<template>
  <div class="h-100">
    <el-card class="box-card w-100 box h-100">
      <div class="search_content flex">
        <div>
          <div class="ov w-600">
            <h1>
              <img
                src="@/assets/images/search/qwzs_text@3x.png"
                alt="全网智搜"
                width="166"
              />
            </h1>
            <el-tabs v-model="activeTab">
              <el-tab-pane
                v-for="item in typesGroup"
                :key="item.value"
                :label="item.label"
                :name="item.value"
              ></el-tab-pane>
            </el-tabs>
            <div class="input-box">
              <div :class="['triangle', 'triangle-' + activeTab]"></div>
              <input
                type="text"
                v-model.trim="value"
                @keyup.enter="handleInput"
                :placeholder="placeholder[activeTab]"
              />
              <span class="iconfont icon-sousuo" @click="handleInput"></span>
            </div>
          </div>
          <!-- 全网热门信息 -->
          <div class="hot-infor">
            <div class="mod-title">
              <img
                src="../../assets/images/search/hot.png"
                width="16"
                alt=""
              />全网热门信息
            </div>
            <el-row :gutter="50">
              <el-col :span="12">
                <ul class="mod-list">
                  <li>
                    <h3>
                      <span class="num-tit">序号</span>
                      <span class="mod-list-title1">标题</span>
                      <span class="tag">数据量</span>
                    </h3>
                  </li>
                  <li
                    v-for="(item, index) in ranking.hits.hits"
                    :key="index"
                    v-if="index <= 4"
                    @click="detail(item._source.rowkey_s)"
                  >
                    <span
                      :class="
                        index == 0
                          ? 'num bg-555'
                          : index == 1
                          ? 'num bg-689'
                          : index == 2
                          ? 'num bg-8ea'
                          : 'num'
                      "
                      >{{ index + 1 }}</span
                    >
                    <a class="mod-list-title1" :title="item._source.title">{{
                      item._source.title
                    }}</a>
                    <span class="tag"
                      >{{
                        ranking.aggregations.category_stats.buckets[index]
                          .doc_count
                      }}
                    </span>
                  </li>
                </ul>
              </el-col>
              <el-col :span="12">
                <ul class="mod-list">
                  <li>
                    <h3>
                      <span class="num-tit">序号</span>
                      <span class="mod-list-title1">标题</span>
                      <span class="tag">数据量</span>
                    </h3>
                  </li>
                  <li
                    v-for="(item, index) in ranking.hits.hits"
                    :key="index"
                    v-if="index > 4"
                    @click="detail(item._source.rowkey_s)"
                  >
                    <span class="num">{{ index + 1 }}</span>
                    <a class="mod-list-title1" :title="item._source.title">{{
                      item._source.title
                    }}</a>
                    <span class="tag"
                      >{{
                        ranking.aggregations.category_stats.buckets[index]
                          .doc_count
                      }}
                    </span>
                  </li>
                </ul>
              </el-col>
            </el-row>
          </div>
          <!--@end 全网热门信息 -->
        </div>
      </div>
    </el-card>
    <article-detail ref="detail" />
  </div>
</template>
<script>
import { typesGroup } from './constants';
import { newsRanking } from '@/api/search';
import articleDetail from '@components/common/article-detail.vue';

export default {
  data() {
    return {
      typesGroup,
      activeTab: typesGroup[0].value,
      placeholder: {
        info: '请输入信息关键词',
        source: '请输入网站名称、微博名称/ID、微信公众号名称/微信号',
        policy: '请输入政策关键词'
      },
      value: '',

      ranking: { hits: { hits: [] } }
    };
  },
  components: {
    articleDetail
  },
  mounted() {
    this.loadRanking();
  },
  methods: {
    detail(rowkey) {
      this.$refs.detail.showDetail(rowkey, 'overview', null, null, 1);
    },

    isset() {
      const a = arguments;
      const l = a.length;
      let i = 0;
      let undef;
      if (l === 0) {
        throw new Error('Empty isset');
      }
      while (i !== l) {
        if (a[i] === undef || a[i] === null) {
          return false;
        }
        i++;
      }
      return true;
    },

    loadRanking() {
      newsRanking()
        .then(res => {
          if (res.data.state) {
            this.ranking = res.data.data;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(() => {
          this.$message.error('热点新闻加载失败');
        });
    },

    handleInput() {
      if (this.value.length >= 1) {
        this.$router.push({
          path: 'search/index',
          query: {
            type: this.activeTab,
            value: this.value
          }
        });
      } else {
        this.$message.warning('请输入信息关键词');
      }
    }
  }
};
</script>
<style scoped>
.box {
  background-image: url(../../assets/images/search/qwzs_bg@3x.png);
  background-repeat: no-repeat;
  background-size: 100% 270px;
  -moz-background-size: 100% 270px;
  background-position: 0 bottom;
  font-size: 14px;
}
.box >>> .el-tabs {
  margin-top: 50px;
}
.search_content {
  height: 88vh;
  justify-content: center;
  align-items: center;
}
.w-600 {
  width: 600px;
  margin: 0 auto 80px;
}
.search_content h1 {
  font-size: 40px;
  font-weight: 400;
  background: linear-gradient(
    172deg,
    rgba(73, 132, 230, 1) 0%,
    rgba(139, 246, 255, 1) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}
/*提示样式*/
.input-box {
  position: relative;
  width: 580px;
  height: 44px;
  background: #f4f6f9;
  line-height: 44px;
  padding: 0 10px;
  line-height: 44px;
  border-radius: 4px;
  text-align: left;
  margin: 10px 0;
}
.triangle {
  position: absolute;
  border-style: solid;
  display: block;
  width: 0;
  top: -10px;
  bottom: auto;
  border-width: 0 10px 10px;
  border-color: #f4f6f9 transparent;
}
.triangle-info {
  left: 64px;
}
.triangle-source {
  left: 130px;
}
.triangle-policy {
  left: 199px;
}
.input-box input {
  background: none;
  border: none;
  width: 550px;
}
input:focus {
  outline: none;
}
.input-box .icon-sousuo {
  float: right;
  color: #4674cc;
  font-size: 24px;
}
.search_content >>> .el-tabs__content {
  padding: 20px 0 0;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.search_content >>> .el-tabs__active-bar {
  display: none;
}
.search_content >>> .el-tabs--top .el-tabs__item {
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
}
/* 热门信息 */
.hot-infor {
  width: 880px;
  margin: 0 auto;
}
.hot-infor .mod-title {
  margin-left: 0;
  border: none;
  padding-left: 0;
}
.hot-infor .mod-title img {
  margin-right: 15px;
}
.hot-infor .tag {
  width: 80px;
}
.hot-infor .mod-list-title1 {
  width: calc(100% - 140px);
}
</style>
